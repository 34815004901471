<template>
<!-- about -->
  <div class="box">
    <div class="header">
      <div class="whhh">
        <div class="header_img">
          <img
            @click="dialogVisible = true"
            class="header_imgs"
            src="../../assets/guanyuwomen_banner.png"
            alt=""
          />
        </div>
      </div>
      <div style="height: 530px"></div>
    </div>
    <div class="module">
      <div class="w">
        <div class="title">关于职培通</div>
        <div class="introduce">职培通为企业打造数智化培训生态系统</div>
        <div class="brief">
          &nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;职培通团队沉淀二十余年的职业培训领域经验，面向企业提供“平台+内容+服务”一站式数智化培训，一键生成企业在线培训平台。利用智能化SaaS工具、数字化课程资源以及专业化服务团队，
          助力企业打造数智化培训生态系统。并提供全流程、可实现的补贴培训落地。
        </div>
        <div class="title">四大业务模块</div>
        <div class="tab">
          <div
            class="item"
            style="
              border-bottom: 1px solid #1276fb;
              border-right: 1px solid #1276fb;
            "
          >
            <img src="../../assets/weiqiye_icon.png" alt="" />
            <div class="title1">为企业</div>
            <div class="introduce1" style="width: 220px">
              提供平台+内容+服务<br />
              帮助企业开展数智化培训
            </div>
          </div>
          <div
            class="item"
            style="
              border-top: 1px solid #1276fb;
              border-right: 1px solid #1276fb;
            "
          >
            <img src="../../assets/weigeren_icon.png" alt="" />
            <div class="title1">为个人</div>
            <div class="introduce1" style="width: 260px">
              提供覆盖全职涯提升课程体系 使个人职业技能有所提升成长
            </div>
          </div>
          <div
            class="item"
            style="
              border-bottom: 1px solid #1276fb;
              border-right: 1px solid #1276fb;
            "
          >
            <img src="../../assets/weiyuanxiao_icon.png" alt="" />
            <div class="title1">为院校</div>
            <div class="introduce1" style="width: 220px">
              为数智化转型升级 提供线上培训SaaS工具
            </div>
          </div>
          <div class="item" style="border-top: 1px solid #1276fb">
            <img src="../../assets/weizhengfu_icon.png" alt="" />
            <div class="title1">为政府</div>
            <div class="introduce1" style="width: 260px">
              提供线上平台培训工具 及符合政府要求的数据报告
            </div>
          </div>
        </div>
        <div class="content">
          <div class="title2">企业培训数智化赋能</div>
          <div class="introduce2">
            沉淀行业二十余年经验的专家所打造的职培平台
          </div>
          <div class="list">
            <div class="item">
              <div class="item_1">崭新的数智化培训模式</div>
              <div class="item_2">大幅提高培训效果，为企业人才培训赋能</div>
            </div>
            <div class="item">
              <div class="item_1">企业培训全程信息化管理</div>
              <div class="item_2">
                在线实时跟进员工培训情况，优化培<br />训过程，提升管理效率
              </div>
            </div>
            <div class="item">
              <div class="item_1">申请政府培训补贴</div>
              <div class="item_2">
                企业培训政府买单，有效降低<br />培训成本。
              </div>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="title2">员工个人职业技能提升</div>

          <div class="list">
            <div class="item">
              <div class="item_1">课程涵盖个人全职涯各个阶段</div>
              <div class="item_2">国家标准职业数字化资源，满足学习需要</div>
            </div>
            <div class="item">
              <div class="item_1">拓展个人兴趣/副业</div>
              <div class="item_2">
                发掘个人所喜爱擅长的职业技能，成为一技之长
              </div>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="title2">为培训机构数智化转型升级赋能</div>
          <div class="list">
            <div class="item">
              <div class="item_1">让传统培训机构拥有数智化培训工具</div>
              <div class="item_2">
                赋能培训机构开展线上培训，打造数智化生态培训系统
              </div>
            </div>
            <div class="item">
              <div class="item_1">提供数字化培训报告</div>
              <div class="item_2">为机构在培训后提供全面的数字化培训报告</div>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="title2">为政府提供线上培训平台</div>
          <div class="list">
            <div class="item">
              <div class="item_1">线下培训正逐步线上化</div>
              <div class="item_2">
                职培通是国家人社部推荐全国线上<br />
                培训平台、上海市首批“互联网+职<br />
                业技能培训”线上平台
              </div>
            </div>
            <div class="item">
              <div class="item_1">标准补贴课程</div>
              <div class="item_2">
                政府补贴课程均为国家标准数字<br />
                化资源课程
              </div>
            </div>
            <div class="item">
              <div class="item_1">符合政府要求的数据报告</div>
              <div class="item_2">
                满足政府培训要求，可提供相关<br />
                培训补贴数据报告
              </div>
            </div>
          </div>
        </div>
        <div style="height: 0.5rem"></div>
      </div>
    </div>
    <el-dialog
      top="45vh"
      title="立即咨询"
      :visible.sync="dialogVisible"
      width="30%"
      center
    >
      <div style="text-align: center">
        <img
          class="dialog_img"
          src="../../assets/kefu_erweima_imgs.jpg"
          alt=""
        />
      </div>
      <div style="text-align: center; margin-top: 0.2rem; font-size: 0.16rem">
        400-100-2925
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      imglist: [
        {
          url: require("../../assets/shouye_banner1.jpg"),
        },
        {
          url: require("../../assets/shouye_banner1.jpg"),
        },
        {
          url: require("../../assets/shouye_banner1.jpg"),
        },
        {
          url: require("../../assets/shouye_banner1.jpg"),
        },
      ],
    };
  },
  methods: {
    popup() {
      window.location.href =
        "https://www.zpton.com/allcourses?type=1&name=%E8%99%8E%E5%B9%B4%E5%B0%B1%E5%9C%B0%E8%BF%87%C2%B7%E6%8A%80%E8%83%BD%E5%85%8D%E8%B4%B9%E5%AD%A6&id=234&is_type=2";
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.box {
  position: relative;
  overflow: hidden;
}
.toutu {
  position: absolute;
  left: 50%;
  height: 530px;
  width: 1920px;
  transform: translateX(-50%);
}
.header_imgs {
  width: 1200px;
  display: block;
  margin: 0 auto;
  margin-top: 50px;
}

.img {
  height: 530px;
  width: 1900px;
}
.module {
  overflow: hidden;
  background: url("../../assets/beijingtu.png");
  .title {
    text-align: center;
    margin-top: 93px;
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
  .introduce {
    text-align: center;
    margin-top: 8px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #777777;
  }
  .brief {
    margin-top: 40px;
    font-size: 22px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
  }
  .tab {
    margin-top: 66px;
    margin-bottom: 110px;
    display: flex;
    .item {
      // padding: .4rem;
      box-sizing: border-box;
      width: 25%;
      height: 260px;
      img {
        display: block;
        margin: 40px auto 0;
      }
      .title1 {
        margin-top: 10px;
        text-align: center;
        font-size: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
      }
      .introduce1 {
        margin: 0 auto;
        margin-top: 20px;

        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .content {
    background-color: #fff;
    padding: 73px;
    box-sizing: border-box;
    box-shadow: 0px 2px 7px 0px rgba(204, 203, 203, 0.5);
    margin-bottom: 40px;
    .title2 {
      text-align: center;
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
    }
    .introduce2 {
      margin-top: 13px;
      text-align: center;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #777777;
    }
    .list {
      margin-top: 79px;
      display: flex;
      justify-content: space-between;
      .item {
        // width: 2.73rem;
        .item_1 {
          font-size: 23px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
          &::before {
            margin-right: 5px;
            content: "";
            display: inline-block;
            border-radius: 50%;
            width: 13px;
            height: 13px;
            background: #1276fb;
          }
        }
        .item_2 {
          margin-top: 20px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }
}
</style>
